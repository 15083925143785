import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import ReviewPost from '../components/review-post'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { toPlainText } from '../lib/helpers'

export const query = graphql`
  query ReviewTemplateQuery($id: String!) {
    post: sanityReview(id: { eq: $id }) {
      id
      publishedAt
      _rawExcerpt
      rating
      sourceLink {
        url
        cta
      }
      status
      textColor {
        hex
      }
      bgColor {
        hex
      }
      relatedPosts {
        title
        publishedAt
        _updatedAt
        _rawExcerpt(resolveReferences: { maxDepth: 5 })
        rating
        status
        textColor {
          hex
        }
        bgColor {
          hex
        }
        mainImage {
          ...ImageWithPreview
          caption
          alt
        }
        slug {
          current
        }
      }
      reviews {
        mainImage {
          ...ImageWithPreview
          caption
          alt
        }
        reviewedAt
        title
        rating
        slug {
          current
        }
        _rawReviewContent(resolveReferences: { maxDepth: 5 })
      }
      title
      slug {
        current
      }
      _updatedAt
      categories {
        _id
        title
        slug {
          current
        }
      }
      mainImage {
        ...ImageWithPreview
        caption
        alt
      }
      authors {
        _key
        author {
          image {
            ...ImageWithPreview
          }
          name
        }
      }
    }
  }
`

const ReviewPostTemplate = (props) => {
  const { data, errors, pageContext } = props
  const post = data && data.post

  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {post && (
        <SEO
          title={post.title || 'Untitled'}
          description={toPlainText(post._rawExcerpt)}
          image={post.mainImage}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {post && <ReviewPost {...post} {...pageContext} />}
    </Layout>
  )
}

export default ReviewPostTemplate