import React from 'react'
import { globalHistory } from '@reach/router'
import tw, { css, theme } from 'twin.macro'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

  const breakpoints = [640, 768, 1024]

  const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)
  const maxMq = breakpoints.map(bp => `@media (max-width: ${bp}px)`)

const Feedback = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isValidated: false,
      fname: '',
      email: '',
      message: '',
      cta: '',
      feedbackMsg: null,
      title: `${props.title}`
        ? `Personal Reviews: ${props.title}`
        : 'Personal Reviews',
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch(`${globalHistory.location.pathname}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(response =>
        this.setState({
          feedbackMsg: 'Feedback form is submitted successfully!',
        }),
      )
      .catch(error => alert(error))
  }

  render() {
    const { fname, email, message, feedbackMsg, title } = this.state
    return (
      <div
        className="form"
        tw="md:border border-t p-0 pt-6 md:p-8 md:rounded md:shadow-sm font-serif mt-0"
        css={{
          gridColumn: '1/-1',
          width: '100%',
          margin: '0 auto',
          borderColor: `${theme`borderColor.default`}`,
          [mq[1]]: {
            backgroundColor: `${theme`backgroundColor.secondary`}`,
          }
        }}
      >
        <h3 tw="prose prose-2xl md:text-3xl mb-4">Share your opinion</h3>
        {feedbackMsg ? (
          <p
            tw="leading-normal prose prose-lg md:prose-xl"
            css={{
              color: `${'var(--text-softer)'}`,
            }}
          >
            Thank you for taking the time to share your opinion!
            <br /> I truly appreciate it{' '}
            <span role="img" aria-label="emoji">
              🙇‍♂️
            </span>
          </p>
        ) : (
          <p
            tw="leading-normal prose prose-lg md:prose-xl"
            css={{
              color: `${'var(--text-softer)'}`,
            }}
          >
            I'd love to hear more and learn from your perspective. Are any of these reviews
            factually wrong or outdated? What did I miss? Let me know more below.
          </p>
        )}

        <form
          name={title}
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
          tw="flex flex-col space-y-4 items-end font-sans font-normal text-base mt-0"
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value={title} />
          <div hidden>
            <label>
              Don’t fill this out: <input name="bot-field" onChange={this.handleChange} />
            </label>
          </div>
          {feedbackMsg ? (
            ''
          ) : (
            <>
              <div tw="w-full">
                <label htmlFor={'fname'} tw="inline-flex mb-2 text-xs sm:text-sm text-softer pl-4">
                  Name
                </label>
                <input
                  required
                  tw="block w-full text-base rounded focus:ring-0 bg-primary text-primary border-none"
                  css={{
                    boxShadow: '0 0 0 1px var(--color-border)',
                    ':focus': {
                      boxShadow: '0 0 0 1px var(--color-primary)',
                    },
                  }}
                  type={'text'}
                  name={'fname'}
                  value={fname}
                  onChange={this.handleChange}
                  id={'fname'}
                  placeholder={'Your nick name'}
                />
              </div>
              <div tw="w-full">
                <label htmlFor={'email'} tw="inline-flex mb-2 text-xs sm:text-sm text-softer pl-4">
                  Email
                </label>
                <input
                  required
                  tw="block w-full text-base rounded focus:ring-0 bg-primary text-primary border-none"
                  css={{
                    boxShadow: '0 0 0 1px var(--color-border)',
                    ':focus': {
                      boxShadow: '0 0 0 1px var(--color-accent)',
                    },
                  }}
                  type={'email'}
                  name={'email'}
                  value={email}
                  onChange={this.handleChange}
                  id={'email'}
                  placeholder={'Your email address'}
                />
              </div>
              <div tw="w-full">
                <label
                  htmlFor={'message'}
                  tw="inline-flex mb-2 text-xs sm:text-sm text-softer pl-4"
                >
                  Comment
                </label>
                <textarea
                  required
                  tw="block w-full text-base rounded focus:ring-0 bg-primary text-primary border-none"
                  rows={10}
                  css={{
                    boxShadow: '0 0 0 1px var(--color-border)',
                    ':focus': {
                      boxShadow: '0 0 0 1px var(--color-primary)',
                    },
                  }}
                  type={'textarea'}
                  name={'message'}
                  value={message}
                  onChange={this.handleChange}
                  id={'message'}
                  placeholder={'Your opinion on the review...'}
                />
              </div>
              <button
                tw="w-full rounded-full p-2 font-medium"
                css={{
                  backgroundColor: `${theme`colors.accent`}`,
                  color: `var(--text-cta)`,
                  boxShadow: '0 0 0 1px var(--color-primary), 0 1px 1px 1px var(--color-accent)',
                  '&:hover, &:focus': {
                    backgroundColor: `${theme`colors.primary`}`,
                  },
                }}
                type="submit"
              >
                Share with Ajmal
              </button>
            </>
          )}
        </form>
      </div>
    )
  }
}

export default Feedback
